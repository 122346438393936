<template>
  <li>
    <a
      :href="link!.url?.path"
      :data-route-name="routeName"
      @mouseover="onMouseOver"
      @mouseleave="onMouseLeave"
    >
      <span>{{ link!.label }}</span>
      <svg
        v-if="subtree && subtree.length && level > 0"
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
      >
        <path
          d="M8.053 8.355c.193-.195.193-.517 0-.711l-3.26-3.289c-.193-.195-.192-.514.002-.709l1.371-1.371c.194-.194.512-.193.706.001l5.335 5.369c.195.195.195.515 0 .708l-5.335 5.37c-.194.192-.512.193-.706.002l-1.371-1.371c-.194-.195-.195-.514-.002-.709l3.26-3.29z"
        />
      </svg>
    </a>
    <ul v-if="subtree && subtree.length && active">
      <AdminToolbarSubtree
        v-for="(subLink, i) in subtree"
        :key="i"
        :level="level + 1"
        v-bind="subLink"
        @hover="localActive = i"
        :active="localActive === i"
      />
    </ul>
  </li>
</template>

<script lang="ts">
export default {
  name: 'AdminToolbarSubtree',
}
</script>

<script lang="ts" setup>
import { PropType, ref, watch } from 'vue'

export type AdminMenuLinkFragment = {
  link: {
    label: string
    url?:
      | { routeName: string; path: string }
      | { routeName: string; path: string }
      | { routeName: string; path: string }
      | { path: string }
      | { path: string }
      | null
  }
}

const emit = defineEmits(['hover'])

const localActive = ref(-1)

let timeout: any = null

function onMouseOver() {
  clearTimeout(timeout)
  timeout = setTimeout(() => {
    emit('hover')
  }, 300)
}

function onMouseLeave() {
  clearTimeout(timeout)
}

const props = defineProps({
  level: {
    type: Number,
    default: 0,
  },
  link: {
    type: Object as PropType<AdminMenuLinkFragment['link']>,
  },
  subtree: {
    type: Array as PropType<AdminMenuLinkFragment[]>,
  },
  active: {
    type: Boolean,
    default: false,
  },
})

watch(
  () => props.active,
  (isActive) => {
    if (!isActive) {
      localActive.value = -1
    }
  }
)

const routeName = computed(() => {
  const url = props.link?.url
  if (url && 'routeName' in url) {
    return url.routeName
  }
  return ''
})
</script>

<template>
  <div v-if="data" class="admin-toolbar" :style="style">
    <ul v-if="data.links?.length">
      <AdminToolbarSubtree
        v-for="(link, i) in data.links"
        :key="i"
        v-bind="link"
        @hover="active = i"
        :active="active === i"
      />
    </ul>
    <Teleport to="body">
      <div
        v-if="active >= 0"
        class="admin-toolbar-overlay"
        @click="active = -1"
        @mouseenter="mouseEnterOverlay"
        @mouseleave="mouseLeaveOverlay"
      />
    </Teleport>
  </div>
</template>

<script lang="ts">
export default {
  name: 'AdminToolbar',
}
</script>

<script lang="ts" setup>
import AdminToolbarSubtree from './Subtree.vue'

const active = ref(-1)

let timeout: any = null

function mouseEnterOverlay() {
  clearTimeout(timeout)

  timeout = setTimeout(() => {
    active.value = -1
  }, 800)
}

function mouseLeaveOverlay() {
  clearTimeout(timeout)
}

const { data } = useLazyAsyncData('adminToolbar', () => {
  return useGraphqlQuery('adminToolbar').then((v) => {
    const menu = v.data.menu
    const links =
      menu && 'links' in menu
        ? (menu.links[menu.links.length - 1]?.subtree || []).filter(
            (v) => !!v.link,
          )
        : []
    return { links, environment: v.data.activeEnvironment }
  })
})

const style = computed(() => {
  if (data.value?.environment?.bgColor) {
    return {
      borderTopColor: data.value.environment.bgColor,
      '--vuepal-environment-color': data.value.environment.bgColor,
    }
  }
  return {}
})
</script>

<style lang="scss">
@mixin adminToolbarIcon($routeName, $icon) {
  $url: '/themes/contrib/gin/dist/media/sprite.svg#' + $icon;

  &[data-route-name='#{$routeName}']:before {
    -webkit-mask-image: url($url);
    mask-image: url($url);
  }
}

.admin-toolbar {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 900001;
  background: white;
  box-shadow:
    0 0 4px rgb(0 0 0 / 10%),
    0 2px 6px 2px rgb(0 0 0 / 3%);
  font-size: 14px;
  --vuepal-environment-color: #ccc;
  border-top: 6px solid var(--vuepal-environment-color);

  a {
    display: flex;
    padding: 0.5rem 1rem;
    color: black;
    justify-content: space-between;
    align-items: center;
    &:hover {
      background: #eee;
    }
  }

  > ul {
    display: flex;
    margin-left: min(5vw, 32px);
    margin-right: min(5vw, 48px);
    ul {
      position: absolute;
      background: white;
      width: 16rem;
      box-shadow: 0 8px 14px rgb(0 0 0 / 15%);
    }

    /* First level link. */
    > li > a {
      padding-top: 0;
      padding-bottom: 0;
      height: 53px;
      &:before {
        content: '';
        width: 1.25em;
        height: 1.25em;
        background: currentColor;
        display: inline-block;
        margin-right: 0.5em;
        -webkit-mask-position: center center;
        mask-position: center center;
        -webkit-mask-size: 17px 17px;
        mask-size: 17px 17px;
        -webkit-mask-repeat: no-repeat;
        mask-repeat: no-repeat;
      }

      /* Map route names to the correct icon in the gin sprite. */
      @include adminToolbarIcon('system.admin_content', 'content-view');
      @include adminToolbarIcon('system.admin_structure', 'structure-view');
      @include adminToolbarIcon('system.themes_page', 'appearance-view');
      @include adminToolbarIcon('system.modules_list', 'extend-view');
      @include adminToolbarIcon('system.admin_config', 'config-view');
      @include adminToolbarIcon('entity.group.collection', 'group-view');
      @include adminToolbarIcon('entity.user.collection', 'people-view');
      @include adminToolbarIcon('system.admin_reports', 'reports-view');
      @include adminToolbarIcon('help.main', 'help-view');
      @include adminToolbarIcon('commerce.admin_commerce', 'commerce-view');
      @include adminToolbarIcon('<front>', 'gin-view');
      @include adminToolbarIcon('tmgmt.admin_tmgmt', 'tmgmt-view');

      &[data-route-name='<front>'] {
        padding: 0;
        width: 54px;
        &:before {
          width: 100%;
          height: 100%;
          -webkit-mask-size: 28px 28px;
          mask-size: 28px 28px;
          background: var(--vuepal-environment-color);
        }
        span {
          display: none;
        }
      }
    }
    > li > ul {
      top: 100%;
      left: 0;

      > li ul {
        left: 100%;
        top: 0;
      }
    }
  }
  li {
    position: relative;
    &:hover {
      > a {
        background: #eee;
      }
    }
  }
}

.admin-toolbar-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(black, 0.2);
  z-index: 900000;
}
</style>
